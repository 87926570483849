import React from 'react'
import {
  Box,
  Label,
  Button,
  Grid,
} from 'theme-ui'

const options = [
  {
    value: 0,
    label: 'Studio',
  },
  {
    value: 1,
    label: '1 Bedroom',
  },
  {
    value: 2,
    label: '2 Bedroom',
  },
]

export default function FloorplanOption({ value, name, label, onChange }){
  return(
    <Box variant='boxes.formGroup'>
      <Label htmlFor={name}>{label}</Label>
      <Grid columns={3}>
        {options.map(option =>
          <Button key={option.label} variant={value === option.value ? 'selected' : 'muted'} onClick={() => onChange(option.value)}>
            {option.label}
          </Button>
        )}
      </Grid>
    </Box>
  );
};
