import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, Label } from 'theme-ui';

import './Calendar.css'

export default function MoveInDate({ value = new Date(), name, label, onChange }) {
  return (
    <Box variant='boxes.inputGroup'>
      <Label htmlFor={name}>{label}</Label>
        <DatePicker
          name={name}
          selected={value}
          minDate={new Date()}
          onChange={onChange}
          dateFormat='MMM do'
        />
    </Box>
  );
};

