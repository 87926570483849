import React from 'react'

import CheckBoxGroup from './CheckBoxGroup'

const options = [
  {
    label: 'Grocery',
    value: 'Grocery',
  },
  {
    label: 'Cafes',
    value: 'Cafes',
  },
  {
    label: 'Pet Activities',
    value: 'Pet Activities',
  },
  {
    label: 'Happy Hour',
    value: 'Happy Hour',
  },
]

export default function NeighborhoodButtonGroup (props) {
  return (
    <CheckBoxGroup {...props} options={options} />
  );
};