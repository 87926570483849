import React from 'react'
import {
  Box,
  Label,
  Input,
} from 'theme-ui'

import PhoneInput from 'react-phone-number-input/input'

export default function PhoneInputGroup({ value, name, label, onChange, ...props }){
  return(
    <Box variant='boxes.inputGroup'>
      <Label htmlFor={name}>{label}</Label>
      <PhoneInput name={name} country='US' value={value} onChange={onChange} inputComponent={Input} {...props} />
    </Box>
  );
};
