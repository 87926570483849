import React from 'react'

import CheckBoxGroup from './CheckBoxGroup'

const options = [
  {
    label: 'Dog Park',
    value: 'Dog Park',
  },
  {
    label: 'Outdoor Kitchen',
    value: 'Outdoor Kitchen',
  },
  {
    label: 'Fitness Center',
    value: 'Fitness Center',
  },
  {
    label: '24hr Package Pickup',
    value: '24hr Package Pickup',
  },
]

export default function CommunityAmenityGroup (props) {
  return (
    <CheckBoxGroup {...props} options={options} />
  );
};