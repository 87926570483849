import React from 'react'
import {
  Box,
  Label,
  Input,
} from 'theme-ui'

export default function InputGroup({ value, name, label, onChange, ...props }){
  return(
    <Box variant='boxes.inputGroup'>
      <Label htmlFor={name}>{label}</Label>
      <Input name={name} value={value} onChange={e => onChange(e.target.value)} {...props} />
    </Box>
  );
};
