import React from 'react'

import CheckBoxGroup from './CheckBoxGroup'

const options = [
  {
    label: 'Walk-in Closets',
    value: 'Walk-in Closets',
  },
  {
    label: 'Large Windows',
    value: 'Large Windows',
  },
  {
    label: 'In-Home W/D',
    value: 'In-Home W/D',
  },
  {
    label: 'Walk-in Shower',
    value: 'Walk-in Shower',
  },
]

export default function FloorplanAmenityGroup (props) {
  return (
    <CheckBoxGroup {...props} options={options} />
  );
};