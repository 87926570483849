import React, { useState, useEffect, useCallback } from 'react'
import { Flex } from 'theme-ui'
import { Helmet } from 'react-helmet'

import Form from '../components/Form'
import VideoPlayer from '../components/VideoPlayer'

const Home = () => {
  const [id, setId] = useState()
  const [url, setUrl] = useState()
  const [pollingId, setPollingId] = useState()

  const onSubmit = form => {
    setUrl()
    fetch('/.netlify/functions/create-video', {
      method: 'post',
      body: JSON.stringify(form),
    }).then(res => res.json())
      .then(json => {
        if (json.data.message === 'Created')
          setId(json.data.response.id)
      })
  }

  const poll = useCallback(() => {
    fetch('/.netlify/functions/get-video', {
      method: 'post',
      body: JSON.stringify({ id })
    }).then(res => res.json())
      .then(json => {
        if (json.data) {
          const video = json.data.data[0].attributes
          setUrl(video.url)
          setId()
        }
      })
  }, [id])

  useEffect(() => {
    if (id && !pollingId) {
      const interval = setInterval(poll, 15000)
      setPollingId(interval)
    } else if (pollingId && !id) {
      clearInterval(pollingId)
      setPollingId()
    }
  }, [id, pollingId, poll])

  return <Flex as='main' variant='layout.main'>
    <Helmet title='2021 OPTECH Conference & Expo' />
    <Flex variant='boxes.video'>
      <VideoPlayer loading={id ? true : false} url={url} />
    </Flex>
    <Flex variant='boxes.right'>
      <Form disabled={pollingId} onSubmit={onSubmit} onReset={() => setUrl()} />
    </Flex>
  </Flex>
}

export default Home
