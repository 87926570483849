import React, { useState } from 'react';
import { Box, Button, Grid, Alert, Spinner } from 'theme-ui';

import Header from '../components/Header'
import InputGroup from './InputGroup';
import FloorplanSize from './FloorplanOption';
import MoveInDate from './Calendar';
import FloorplanAmenityGroup from './FloorplanAmenityGroup';
import CommunityAmenityGroup from './CommunityAmenityGroup';
import NeighborhoodButtonGroup from './NeighborhoodButtonGroup';
import PhoneInputGroup from './Phone'
import ThemeOptions from './ThemeOptions'

const defaultDate = '2021-12-15T12:00:00'

const themes = [
  {
    id: 'aab651ba-23ef-4b3a-927d-504424734c4b',
    primary: {
      fontFamily: 'Lato, sans-serif',
      bg: '#1f2944',
      color: '#f1c40f',
    },
    secondary: {
      fontFamily: 'Montserrat, sans-serif',
      bg: '#1f2944',
      color: '#eeeeee'
    },
  },
  {
    id: '9a782513-05da-4970-9e19-32f923db0f8e',
    primary: {
      fontFamily: 'Playfair Display, serif',
      bg: '#007AB8',
      color: '#f1c40f',
    },
    secondary: {
      fontFamily: 'Open Sans, sans-serif',
      bg: '#007AB8',
      color: '#eeeeee'
    },
  },
  {
    id: 'eda530eb-f7b4-4cec-b77f-2bf7c0baa5a8',
    primary: {
      fontFamily: 'Gentium Basic, serif',
      bg: '#216C82',
      color: '#ffffff',
    },
    secondary: {
      fontFamily: 'Montserrat, sans-serif',
      bg: '#216C82',
      color: '#eeeeee',
    },
  },
]

const Form = props => {
  const [form, setForm] = useState({ name: '', phone: '', moveDate: new Date(defaultDate), theme: themes[0] })

  const updateForm = obj => setForm({ ...form, ...obj })

  const handleSubmit = e => {
    e.preventDefault()

    const errors = {}

    if (!form.name)
      errors.name = 'First Name is required'

    if (!form.phone)
      errors.phone = 'Mobile is required'

    if (errors.name || errors.phone)
      updateForm({ errors })
    else
      props.onSubmit && props.onSubmit(form)
  }

  const handleReset = () => {
    setForm({
      name: '',
      phone: '',
      moveDate: new Date(defaultDate),
      theme: themes[0]
    })
    props.onReset && props.onReset()
  }

  return(
    <Box
      as='form'
      variant='boxes.form'
      onSubmit={e => e.preventDefault()}>
        <Header />
        {form.errors ? <Box variant='boxes.formGroup'><Alert bg='#D0573E'>First Name and Mobile are required</Alert></Box> : null}
        <Grid columns={3} variant='boxes.formGroup'>
          <InputGroup
            name='name'
            value={form.name}
            label='First Name'
            onChange={name => updateForm({ name })}
            autoCapitalize='words'
            autoComplete='off'
            variant={form.errors && form.errors.name ? 'invalid' : 'input'}
          />
          <PhoneInputGroup
            name='phone'
            value={form.phone}
            label='Mobile'
            onChange={phone => updateForm({ phone })}
            variant={form.errors && form.errors.phone ? 'invalid' : 'input'}
            autoComplete='off'
          />
          <MoveInDate
            name='moveDate'
            value={form.moveDate}
            label='Move-in'
            onChange={moveDate => updateForm({ moveDate })}
          />
        </Grid>
        <FloorplanSize
          name='bedroom'
          value={form.bedroom}
          label='How many bedrooms are you looking for?'
          onChange={bedroom => updateForm({ bedroom })}
        />
        <FloorplanAmenityGroup
          name='floorplanAmenities'
          value={form.floorplanAmenities}
          label='What are you looking for in a home?'
          onChange={floorplanAmenities => updateForm({ floorplanAmenities })}
        />
        <CommunityAmenityGroup
          name='comunityAmenities'
          value={form.communityAmenities}
          label='What are you looking for in a community?'
          onChange={communityAmenities => updateForm({ communityAmenities })}
        />
        <NeighborhoodButtonGroup
          name='neighborhood'
          value={form.neighborhood}
          label='What are you looking for in a neighborhood?'
          onChange={neighborhood => updateForm({ neighborhood })}
        />
        <ThemeOptions
          name='theme'
          value={form.theme}
          label='Select one of our example brands'
          options={themes}
          onChange={theme => updateForm({ theme })}
        />
        <Grid columns={2} variant='boxes.formFooter'>
          <Button type='submit' variant='submit' onClick={handleSubmit} disabled={props.disabled}>
            {props.disabled
              ? <>
                <Spinner size={14} sx={{ color: '#ffffff', marginRight: 2 }} />
                Loading...
              </>
              : 'Submit'}
          </Button>
          <Button type='reset' variant='muted' onClick={handleReset}>
            Reset
          </Button>
        </Grid>
    </Box>
  );
};

export default Form
