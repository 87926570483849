import React from 'react'
import { Button, Label, Box, Grid } from 'theme-ui'

const CheckBoxGroup = ({ name, label, options, value = [], onChange }) => {
  const handleChange = e => {
    const updated = [...value]

    const index = updated.indexOf(e)

    if (index === -1) {
      updated.push(e)

    } else {
      updated.splice(index, 1)
    }

    onChange(updated)
  }

  return(
    <Box variant='boxes.formGroup'>
      <Label htmlFor={name}>{label}</Label>
      <Grid columns={2}>
        {options.map((option, i) =>
          <Button
            key={option.label}
            variant={value.indexOf(option.value) === -1 ? 'muted' : 'selected'}
            onClick={() => handleChange(option.value)}>
            {option.label}
          </Button>
        )}
      </Grid>
    </Box>
  );
}

export default CheckBoxGroup
