import React from 'react'
import { Label, Box, Grid, Text, Divider } from 'theme-ui'

const boxShadow = on => on ? {
  boxShadow: '1px 2px 8px #7F8D99'
} : {}

const ThemeOptions = ({ name, label, options = [], value, onChange }) => {
  return (
    <Box variant='boxes.formGroup'>
      <Label htmlFor={name}>{label}</Label>
      <Grid columns={3}>
        {options.map(option =>
          <Box key={option.id} variant='boxes.theme' sx={boxShadow(option.id === value.id)} onClick={() => onChange(option)}>
            <Text sx={option.primary} variant='text.theme'>{option.primary.fontFamily.split(',')[0]}</Text>
            <Divider sx={{ p: 0, m: 0, height: '2px' }} />
            <Text sx={option.secondary} variant='text.theme'>{option.secondary.fontFamily.split(',')[0]}</Text>
          </Box>
        )}
      </Grid>
    </Box>
  )
}

export default ThemeOptions
